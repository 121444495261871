
// jQuery -->
// require('../../public/js/jquery.js');
// window.$ = window.jQuery = require('../../public/js/jquery.js');
window.$ = window.jQuery = require('jquery');
// bootstrap JS -->
require('bootstrap/dist/js/bootstrap.min');
// WOW JS -->
window.WOW = require('wowjs').WOW;
// magnific-popup JS -->
require('magnific-popup/dist/jquery.magnific-popup.min');
// owl carousel JS -->
require('owl.carousel/dist/owl.carousel.min');
// counter -->
require('counterup/jquery.counterup.min')
require('waypoints/lib/jquery.waypoints.min');
// easing -->
require('../../public/js/easing/jquery.easing.1.3.js');

/*====================================================
                        SERVICES
====================================================*/
$(function () {

    // animate on scroll
    new WOW().init();
});

/*====================================================
                        WORK
====================================================*/
$(document).ready(function($) {

    $("#work").magnificPopup({
        delegate: 'a', // child items selector, by clicking on it popup will open
        type: 'image',
        gallery: {
            enabled: true
        }

    });
});

/*====================================================
                        TEAM
====================================================*/
$(function () {
    let countTeams = $("#totalTeams").val();
    let loopTeams = true;
    if( countTeams > 3) countTeams = 3;
    if(countTeams < 3) loopTeams = false;

    $("#team-members").owlCarousel({
        items: countTeams,
        autoplay: true,
        smartSpeed: 700,
        loop: loopTeams,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            768: {
                items: 3
            }
        }
    });
});


/*====================================================
                        TESTIMONIALS
====================================================*/
$(function () {

    $("#customers-testimonials").owlCarousel({
        items: 1,
        autoplay: true,
        smartSpeed: 700,
        loop: true,
        autoplayHoverPause: true
    });
});


/*====================================================
                        STATS
====================================================*/
$(document).ready(function($) {
    $('.counter').counterUp({
        delay: 10,
        time: 2000
    });

});

/*====================================================
                        CLIENTS
====================================================*/
$(function () {

    $("#clients-list").owlCarousel({
        items: 6,
        autoplay: true,
        smartSpeed: 700,
        loop: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 3
            },
            768: {
                items: 5
            },
            992: {
                items: 6
            }
        }
    });
});

/*====================================================
                        NAVIGATION
====================================================*/
// Show/Hide transparent black navigation
$(function () {

    $(window).scroll(function () {

        if ($(this).scrollTop() < 50) {
            // hide nav
            $("nav").removeClass("vesco-top-nav");
            $("#back-to-top").fadeOut();

        } else {
            // show nav
            $("nav").addClass("vesco-top-nav");
            $("#back-to-top").fadeIn();
        }
    });
});
// Smooth scrolling
$(function () {

    $("a.smooth-scroll").click(function (event) {

        event.preventDefault();

        // get/return id like #about, #work, #team and etc
        var section = $(this).attr("href");

        $('html, body').animate({
            scrollTop: $(section).offset().top - 64
        }, 1250, "easeInOutExpo");
    });
});

// Close mobile menu on click
$(function(){

    $(".navbar-collapse ul li a").on("click touch", function(){

        $(".navbar-toggle").click();
    });
});























